import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'

import IndexLayout from '@/layouts'
import { Pagination } from '@/components/atoms'
import { SEO } from '@/components/molecules'
import { PostCardList, TagBoard } from '@/components/organisms'
import { SearchConnect } from '@/components/connects'
import { TagsPageContext } from '@gatsby-node/createTagsPages'
import { MarkdownRemarkConnection } from 'graphql-types'
import { humanize, pathOptimize } from '@/utils'
import AppConfig from '@/config/app'

import * as styles from './tags.module.scss'

type Props = {
  pageContext: TagsPageContext
  data: {
    allMarkdownRemark: MarkdownRemarkConnection
    thumbnail: any
  }
}

const makeBreadcrumbItems = (pathname: string) => {
  const [tag] = pathname.split('/').slice(2)
  const items = [
    {
      name: 'ホーム',
      link: '/',
      icon: 'home',
    },
    {
      name: humanize(tag),
      link: pathOptimize(`/tags/${tag}`),
    }
  ]
  return items
}

const Tags: React.FC<Props> = ({ pageContext, data }) => {
  const { tag: tagStr } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark

  const location = useLocation()
  const pathname = location.pathname
  const breadcrumbs = makeBreadcrumbItems(pathname)

  const ftitle = humanize(tagStr)
  const paginationContext = { ...pageContext, pathname }
  const thumbnailPath = data.thumbnail.childImageSharp.resize.src
  const { blog } = AppConfig(location)

  const tag = {
    title: `タグ「${tagStr}」の記事一覧`,
    description: `${blog.title}の「${tagStr}」についての記事一覧です。`,
    url: location.href
  }

  return (
    <IndexLayout>
      <SEO type={"tag"} pageType={"tag"} tag={tag} thumbnailPath={thumbnailPath} />
      <SearchConnect>
        <TagBoard className={styles.tag_board} title={ftitle} totalCount={totalCount} breadcrumbs={breadcrumbs} />
        <PostCardList edges={edges} />
        <Pagination context={paginationContext} />
      </SearchConnect>
    </IndexLayout>
  )

}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export const TagsTemplateQuery = graphql`
  query($tag: String, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___createdOn], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            createdOn
            updatedOn
            image {
              publicURL
              childImageSharp {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
          excerpt
        }
      }
    }
    thumbnail: file(relativePath: { eq: "blog-ogp-1200x630.png"}) {
      childImageSharp {
        resize(width: 1200, quality: 90) {
          src
        }
      }
    }
  }
`
export default Tags
